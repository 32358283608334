import React from 'react'
import './Cartstyle.css'
import CartItem from '../../components/cartItem/CartItem';
import { useSelector, useDispatch } from 'react-redux';
import { deleteFromCart } from '../../redux/Actions';

export default function Cartpage({ checkout }) {

    const cartItems = useSelector(state => state.cartItems);
    const orderSummary = useSelector(state => state.orderSummary);
    console.log("Cart page", cartItems);
    const dispatch = useDispatch();

    const handleDeleteFromCart = (item) => {
        dispatch(deleteFromCart(item));
    }

    return (
        <div className='cart-main-div'>
            

            <div className='bottom-div'>
                <div className='left'>
                    {/* just for fun */}
                    <header className='cart-title'>
                <h1>Your Cart {`{${orderSummary.totalItems} items}`}</h1>
                <div className='cart-header'>
                    <div className='left'>
                        <p className='cart-head'>Item</p>
                    </div>
                    <div className='right'>
                        <p className='cart-head'>Price</p>
                        <p className='cart-head'>Quantity</p>
                        <p className='cart-head'>Total</p>
                    </div>


                </div>
                <hr />
            </header>
            <div className='cart-items-div'>
                {/* Map Cart Items here */
                
                    cartItems.map((item) => 
                        <CartItem item={item} />
                    )

                }
            </div>



                </div>

                <div className='summary-div'>
                    <h3>Order Summary</h3>
                    <div className='summary-data'>
                        <p className='row-heading'>Total Items</p>
                        <p className='row-data'>{`${orderSummary.totalItems}`}</p>
                    </div>
                    <hr />
                    <div className='summary-data'>
                        <p className='row-heading'>SubTotal</p>
                        <p className='row-data'>{`$${orderSummary.subTotal}`}</p>
                    </div>
                    <hr />
                    <div className='summary-data'>
                        <p className='row-heading'>SalesTax</p>
                        <p className='row-data'>{`$${orderSummary.salesTax}`}</p>
                    </div>
                    <hr />
                    <div className='summary-data'>
                        <p className='row-heading'>ShippingCharges</p>
                        <p className='row-data'>{`$${orderSummary.shippingCharges}`}</p>
                    </div>
                    <hr />
                    <div className='summary-data'>
                        <p className='row-heading'>GrandTotal</p>
                        <p className='row-data g-total'>{`$${orderSummary.grandTotal}`}</p>
                    </div>

                    <button onClick={()=>{if(orderSummary.totalItems > 0)checkout();}} className='checkout-btn'>Proceed To Check out</button>
                </div>

            </div>

        </div>
    )
}
