import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as style from "react-chatbotify/dist/react-chatbotify.css";
import App from './App';
import { createStore } from 'redux';
import { cartReducer } from './redux/Reducer';
import { Provider } from 'react-redux';
const root = ReactDOM.createRoot(document.getElementById('root'));
const store = createStore(cartReducer);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>

  </React.StrictMode>
);

