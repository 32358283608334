import React from "react";
import "./aboutstyle.css";

const AboutUs = () => {
    const videoId = "NrmMk1Myrxc?autoplay=1&mute=1&showinfo=0&controls=0"; // Replace with a random video ID

    return (
        
            <div className="about-us">

                <div className="video-container-wrapper">
                    <div className="video-container">
                        <iframe
                            title="Random video"
                            src={`https://www.youtube.com/embed/${videoId}`}
                            frameborder="0"
                            allow="autoplay; encrypted-media"
                            allowfullscreen
                        ></iframe>
                    </div>
                </div>

                <div className="container">
                    <h1 className="red-heading">About Us</h1>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
                        vestibulum leo ac lectus ultrices, quis pretium libero tristique.
                        Sed quis lectus vitae elit blandit malesuada. Pellentesque habitant
                        morbi tristique senectus et netus et malesuada fames ac turpis
                        egestas. Nulla facilisi. Aliquam malesuada purus a tincidunt
                        ullamcorper. Duis at bibendum urna. Proin malesuada congue ex, sed
                        molestie felis dictum in. Nam eget ligula est. Etiam a nisl magna.
                        Nulla commodo mi vel justo euismod, quis elementum libero blandit.
                        In hac habitasse platea dictumst. Donec vel lobortis enim. Mauris
                        euismod ante et nibh tristique, at interdum dui efficitur. Duis
                        luctus, massa quis finibus malesuada, nisi diam convallis sapien,
                        at facilisis purus libero sed lacus.
                    </p>
                </div>
            </div>
        
    );
};

export default AboutUs;
