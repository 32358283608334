import React from 'react'
import { baseUrl } from '../../global';
import './CartIstyle.css'
import { incrementItemQuantity, decrementItemQuantity } from '../../redux/Actions';
import { useDispatch } from 'react-redux';

export default function CartItem({item}) {
    var url = item.url;
    url = url.replaceAll('\\', '/');
    var image_src = `${baseUrl}${url}`;

    const dispatch = useDispatch();

    const handleIncrement = (item) => {
        dispatch(incrementItemQuantity(item));
    }

    const handleDecrement = (item) => {
        dispatch(decrementItemQuantity(item));
    }


    return (
        <div className='cart-item-main'>
            <div className='cart-item'>
                <div className='left'>
                    <div className='cart-item-data'>
                        <img src={image_src} />
                        <div className='cart-item-desc'>
                            <p>{`Name : ${item.productName}`}</p>
                            <p className='extra'>{`Exp : ${item.expDate}`}</p>
                            <p className='extra'>{`Company : ${item.company}`}</p>
                        </div>
                    </div>
                </div>
                <div className='right'>
                    <p className=''>{`$${item.productUnitPrice}`}</p>
                    <div className='qty-div'>
                        <button className='qty-mngr' onClick={()=>{handleDecrement(item);}}> - </button>
                        <span className='p-qty'>{`${item.quantity}`}</span>
                        <button className='qty-mngr' onClick={()=>{handleIncrement(item);}}> + </button>
                    </div>
                    <div className='t-div'>
                        <p className=''>{`$${Number(item.productUnitPrice)*Number(item.quantity)}`}</p>
                    </div>
                </div>
            </div>

        </div>
    )
}
