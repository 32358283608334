import { ADD_TO_CART, DELETE_FROM_CART, UPDATE_CART, INCREMENT_CART_ITEM, DECREMENT_CART_ITEM, RESET_STORE } from "./Actions";

const salesTaxPercentage = 5;
// Reducer to add items to cart
const addToCart = (state, action) => {
  const item = action.payload;
  const existingItem = state.cartItems.find(
    (cartItem) => cartItem.id === item.id && cartItem.batchId === item.batchId
  );
  const existingSummary = state.orderSummary;
  console.log(state.cartItems);
  console.log(state.orderSummary);
  if (existingItem) {
    // If the item is already in the cart, increase its quantity
    return {
      ...state,
      cartItems: state.cartItems.map((cartItem) =>
        cartItem.id === item.id
          ? { ...cartItem, quantity: cartItem.quantity + 1 }
          : cartItem
      ),
      orderSummary: {
        ...existingSummary,
        totalItems: existingSummary.totalItems + 1,
        subTotal: Number(existingSummary.subTotal) + Number(item.productUnitPrice),
        salesTax: (salesTaxPercentage * (Number(existingSummary.subTotal) + Number(item.productUnitPrice)) / 100),
        grandTotal: Number(existingSummary.subTotal) + Number(item.productUnitPrice) + (salesTaxPercentage * (Number(existingSummary.subTotal) + Number(item.productUnitPrice)) / 100) + existingSummary.shippingCharges
      }
    };
  } else {
    // If the item is not in the cart, add it
    return {
      ...state,
      cartItems: [...state.cartItems, { ...item, quantity: 1 }],
      orderSummary: {
        ...existingSummary,
        totalItems: existingSummary.totalItems + 1,
        subTotal: Number(existingSummary.subTotal) + Number(item.productUnitPrice),
        salesTax: (salesTaxPercentage * (Number(existingSummary.subTotal) + Number(item.productUnitPrice)) / 100),
        grandTotal: Number(existingSummary.subTotal) + Number(item.productUnitPrice) + (salesTaxPercentage * (Number(existingSummary.subTotal) + Number(item.productUnitPrice)) / 100) + existingSummary.shippingCharges
      }
    };
  }
};

// Reducer to remove items from cart
const removeFromCart = (state, action) => {
  const item = action.payload;
  const existingSummary = state.orderSummary;
  return {
    ...state,
    cartItems: state.cartItems.filter((cartItem) => cartItem.id !== item.id),
    orderSummary: {
      ...existingSummary,
      totalItems: existingSummary.totalItems - 1,
      subTotal: Number(existingSummary.subTotal) - Number(item.productUnitPrice),
      salesTax: (salesTaxPercentage * (Number(existingSummary.subTotal) - Number(item.productUnitPrice)) / 100),
      grandTotal: Number(existingSummary.subTotal) - Number(item.productUnitPrice) + (salesTaxPercentage * (Number(existingSummary.subTotal) - Number(item.productUnitPrice)) / 100) + existingSummary.shippingCharges
    }
  };
};

// Reducer to update the quantity of items in the cart
const updateCart = (state, action) => {
  return {
    ...state
  };
};

const incrementItemQuantity = (state, action) => {
  const cartItem = action.payload;
  const existingSummary = state.orderSummary;
  const newCartItems = state.cartItems.map(item => {
    if (item.id === cartItem.id && cartItem.batchId === item.batchId) {
      return { ...item, quantity: item.quantity + 1 };
    }
    return item;
  });

  return {
    ...state,
    cartItems: newCartItems,
    orderSummary: {
      ...existingSummary,
      totalItems: existingSummary.totalItems + 1,
      subTotal: Number(existingSummary.subTotal) + Number(cartItem.productUnitPrice),
      salesTax: (salesTaxPercentage * (Number(existingSummary.subTotal) + Number(cartItem.productUnitPrice)) / 100),
      grandTotal: Number(existingSummary.subTotal) + Number(cartItem.productUnitPrice) + (salesTaxPercentage * (Number(existingSummary.subTotal) + Number(cartItem.productUnitPrice)) / 100) + existingSummary.shippingCharges
    }
  };
};


const decrementItemQuantity = (state, action) => {
  const cartItem = action.payload;
  const existingSummary = state.orderSummary;
  let newCartItems = state.cartItems.map(item => {
    if (item.id === cartItem.id && cartItem.batchId === item.batchId) {
      return { ...item, quantity: item.quantity - 1 };
    }
    return item;
  });

  newCartItems = newCartItems.filter((item) => item.quantity > 0);

  return {
    ...state,
    cartItems: newCartItems,
    orderSummary: {
      ...existingSummary,
      totalItems: existingSummary.totalItems - 1,
      subTotal: Number(existingSummary.subTotal) - Number(cartItem.productUnitPrice),
      salesTax: (salesTaxPercentage * (Number(existingSummary.subTotal) - Number(cartItem.productUnitPrice)) / 100),
      grandTotal: Number(existingSummary.subTotal) - Number(cartItem.productUnitPrice) + (salesTaxPercentage * (Number(existingSummary.subTotal) - Number(cartItem.productUnitPrice)) / 100) + existingSummary.shippingCharges,
    }
  };
};

const resetStore = (state, action) => {
  return {
    ...initialState
  }
}

const initialState = {
  cartItems: [],
  orderSummary: {
    totalItems: 0,
    subTotal: 0,
    salesTax: 0,
    shippingCharges: 200,
    grandTotal: 0
  }
}

// Main reducer
export const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      return addToCart(state, action);
    case DELETE_FROM_CART:
      return removeFromCart(state, action);
    case UPDATE_CART:
      return updateCart(state, action);
    case INCREMENT_CART_ITEM:
      return incrementItemQuantity(state, action);
    case DECREMENT_CART_ITEM:
      return decrementItemQuantity(state, action);
    case RESET_STORE:
      return resetStore(state, action);
    default:
      return state;
  }
};