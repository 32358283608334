import React from "react";
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import "./footerstyle.css";

const Footer = ({address, contact, email, fb, insta, wa}) => {

  const addArray  = address.split('St');
  
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-4">
            <h4>Contact Us</h4>
            <p>{addArray[0]}</p>
            <p>St{addArray[1]}</p>
            <p>Phone: {contact}</p>
            <p>Email: {email}</p>
          </div>
         
          <div className="col-12 col-sm-4">
            <h4>Follow Us</h4>
            <div className="social-icons">
              <a href={fb}>
                <FaFacebook />
              </a>
              <a href={insta}>
                <FaInstagram />
              </a>
              <a href={wa}>
                <FaWhatsapp />
              </a>
            </div>
          </div>
      
        </div>
      </div>
    </footer>
  );
};

export default Footer;
