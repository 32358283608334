import React from 'react';
import './item.css';
import { baseUrl } from '../../global';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, incrementItemQuantity, decrementItemQuantity } from '../../redux/Actions';

export default function Item({ item }) {

  const dispatch = useDispatch();
  const cartItems = useSelector(state => state.cartItems);

  const handleIncrement = (item) => {
    dispatch(incrementItemQuantity(item));
  }

  const handleDecrement = (item) => {
    dispatch(decrementItemQuantity(item));
  }
  const handleAddToCart = (item) => {
    dispatch(addToCart(item));
  }

  var url = item.url.replaceAll('\\', '/');

  var image_src = `${baseUrl}${url}`;

  var cartItem = cartItems.find((product) => product['id'] === item['id'] && product['batchId'] == item['batchId']);

  return (

    <div className='product'>

      <div className='wrapper'>

        <div className='img-div'>
          <a href='#' className='image-wrapper background-image'>
            <img src={image_src} />
          </a>
        </div>
        {
          cartItem === undefined || cartItem === null ?
            <div className='shop-icon-div' onClick={() => { handleAddToCart(item) }}><AddShoppingCartIcon className='shop-icon' /></div> :

            <div className='item-qty-div'>
              <button className='item-qty-mngr' onClick={() => { handleDecrement(item); }}> - </button>
              <span className='item-p-qty'>{`${cartItem['quantity']}`}</span>
              <button className='item-qty-mngr' onClick={() => { handleIncrement(item); }}> + </button>
            </div>

        }
        <div className='product-price'>Rs {item.productUnitPrice}</div>
        <div className='product-meta'>
          <figure>{item.expDate}</figure>
          <div id = 'product-name' title={item.productName}>
          <figure className='product-name'>{item.productName}</figure>
          </div>
        </div>

        <div className='product-description'>
          <p>{`Category :: ${item.categoryName}`} <br />
            {`Mfg  ::  ${item.mfgDate}`} <br />
            {`Exp  ::   ${item.expDate}`} <br /></p>
        </div>

        <a className='detail'>Detail</a>

      </div>

    </div>
  )
}


