// Action to add items to cart

export const ADD_TO_CART = "ADD_TO_CART";
export const UPDATE_CART = "UPDATE_CART";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const INCREMENT_CART_ITEM = "INCREMENT_CART_ITEM";
export const DECREMENT_CART_ITEM = "DECREMENT_CART_ITEM";
export const RESET_STORE = "RESET_STORE";

export const addToCart = (item) => {
  return {
    type: ADD_TO_CART,
    payload: item,
  };
};

// Action to remove items from cart
export const deleteFromCart = (item) => {
  return {
    type: DELETE_FROM_CART,
    payload: item,
  };
};

// Action to update the quantity of items in the cart
export const updateCart = (itemId, qty) => {
  return {
    type: UPDATE_CART,
    payload: { itemId, qty },
  };
};

// Action to increase the quantity of item in the cart
export const incrementItemQuantity = (item) => {
  return {
    type: INCREMENT_CART_ITEM,
    payload: item,
  };
};

// Action to decrease the quantity of item in the cart
export const decrementItemQuantity = (item) => {
  return {
    type: DECREMENT_CART_ITEM,
    payload: item,
  };
};

// Action to reset store to initialValue
export const resetStore = () => {
  return {
    type: RESET_STORE
  };
};