import React, { useEffect, useState } from "react";
import "./productstyle.css";
import axios from 'axios';
import { FaTruckLoading } from 'react-icons/fa';
import Item from "../../components/item/item";
import { defaultSettings, Settings, baseApiUrl } from "../../global";

console.log();

const ProductListing = ({ call, input, category, pageSize, setCall }) => {
    const [products, setProducts] = useState([]);
    const [offset, setOffset] = useState(0);
    const [filter, setFilter] = useState(false);
    const [filteredArray, setFilteredArray] = useState([]);
    const [filterNo,setFilterNo] = useState(1);

    useEffect(() => {
        // All product will be loaded on the first render / call default is false ** Api will be called when the input is changed
        if (!call && category === undefined) {
            axios.get(`${baseApiUrl}Customer/getProducts?offset=${offset}&size=${pageSize}`).then((res) => {
                if (res.data.length > 0) {
                    setProducts(res.data); // if we get the empty array when next is clicked we will not change the Products array
                }
                else {
                    handlePrev(); // we set the offset to the prev value because no next data was found
                }

            }).catch(err => {
                console.log('Error in making request');
            })
        }

    }, [call, offset])


    useEffect(() => {
        if ((category === '' || category === undefined || category === null || category === 'All Categories') && input !== '' && call) {
            axios.get(`${baseApiUrl}Customer/searchProducts?userInput=${input}`).then((res) => {
                setProducts(res.data);
            }).catch(err => {
                console.log('Error in making request');
            })
        }

        if (category !== '' && category !== undefined && input !== '' && category !== 'All Categories' && call) {
            axios.get(`${baseApiUrl}Customer/searchCatProducts?userInput=${input}&selectedCat=${category}`).then((res) => {
                setProducts(res.data);
            }).catch(err => {
                console.log('Error in making request');
            })
        }

        if (category !== '' && category !== undefined && input === '' && call) {
            if (category === 'All Categories') {
                axios.get(`${baseApiUrl}Customer/getProducts?offset=${offset}&size=${pageSize}`).then((res) => {
                    if (res.data.length > 0) {
                        setProducts(res.data); // if we get the empty array when next is clicked we will not change the Products array
                    }
                    else {
                        handlePrev(); // we set the offset to the prev value because no next data was found
                    }

                }).catch(err => {
                    console.log('Error in making request');
                })
            }
            else {
                axios.get(`${baseApiUrl}Customer/searchCatProducts?userInput=${category}&selectedCat=${category}`).then((res) => {
                    setProducts(res.data);
                }).catch(err => {
                    console.log('Error in making request');
                })
            }

            setCall(false);

        }

    }, [call])


    useEffect(()=>{
        filterData(filterNo);
    },[products])


    const filterData = (f) => {
        setFilter(true);
        if (f == 2 && products.length > 0) {
            setFilteredArray([...products].sort((a, b) => Number(a['productUnitPrice']) - Number(b['productUnitPrice'])));
            console.log(filteredArray);
        }
        else if (f == 3 && products.length > 0) {

            setFilteredArray([...products].sort((a, b) => Number(b['productUnitPrice']) - Number(a['productUnitPrice'])));
            console.log(filteredArray);

        }
        else {
            setFilter(false);
            setFilteredArray([]);
        }

    }

    const handleNext = () => {
        console.log(offset);
        setOffset(offset + Number(pageSize));
        console.log(offset);
    }

    const handlePrev = () => {
        console.log(pageSize);
        console.log(offset);
        if (offset >= pageSize) {
            setOffset(offset - Number(pageSize));
        }

    }



    return (

        <div className='mid-section'>
            <div className='mid-section-header'>
                <p>Products Listing</p>
                <div className='mid-section-picker'>
                    <select onChange={(e) => {
                        setFilterNo(e.target.value);
                        filterData(e.target.value);
                    }} className='picker' defaultValue=''>
                        <option value="" disabled selected key={0}>Filter Options</option>
                        <option key={1} value={1}>No filter</option>
                        <option key={2} value={2}>Price: Low to High</option>
                        <option key={3} value={3}>Price: High to Low</option>
                    </select>
                </div>
            </div>
            {products.length > 0 ?
                <div className='items-div'>
                    {

                        filter ? filteredArray.map((item, index) => <Item item={item}
                        />
                        )
                            :
                            products.map((item, index) => <Item item={item}
                            />
                            )}
                </div> :
                <FaTruckLoading />
            }

            <div>
                <div className='pagination-btn'>
                    <button onClick={handlePrev}>Prev</button>
                    <button onClick={handleNext}>Next</button>
                </div>
            </div>

        </div>

    );
};

export default ProductListing;
