import axios from 'axios';

const defaultSettings = {
    companyName : "ITS",
    companyAddress : "123 Main City, State ZIP",
    companyContact : "(92) 321-5566958",
    companyEmail : "example.company.com",
    fbUrl: "https://facebook.com",
    instaUrl : "https://facebook.com",
    whatsappUrl : "https://facebook.com",
    pageSize : 16
};
const baseUrl = "https://posapis.intellitechsol.com/"
const baseApiUrl = baseUrl + "api/";

const Settings = async () => {
    let set  = await axios.get(baseApiUrl+'Customer/getSettings').then((res) => {
           
                return res.data;
            
        })   

    return set;
}



export {Settings, defaultSettings, baseUrl,baseApiUrl};
