import React, { useState, useEffect } from 'react'
import './Checkoutstyle.css'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import { baseApiUrl } from '../../global';
import { resetStore } from '../../redux/Actions';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function Checkoutpage() {
    const [error, setError] = useState(false);
    const [display, setDisplay] = useState(false);
    const [checked, setChecked] = useState(true);
    const [otpClicked, setOtpClicked] = useState(false);
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [contact, setContact] = useState('');
    const [optionalAddress, setOptionalAddress] = useState('');

    const [payerName, setPayerName] = useState('');
    const [payerAddress, setPayerAddress] = useState('');
    const [payerContact, setPayerContact] = useState('');

    const [otp, setOtp] = useState('');
    const [requestId, setRequestId] = useState('');

    const orderSummary = useSelector(state => state.orderSummary);
    const saleDetail = useSelector(state => state.cartItems);

    const dispatch = useDispatch();

    const handleStoreReset = () => {
        dispatch(resetStore());
    }

    const handleOpen = () => {
        if (name !== '' && contact !== '' && address !== '' && contact.length === 11 && saleDetail.length > 0) {
            if (!checked) {
                if (payerName !== '' && payerContact !== '' && payerAddress !== '' && payerContact.length === 11) {
                    setOpen(true);
                    setError(false);
                }
                else {
                    setError(true);

                }
            }
            else {
                setError(false);
                setOpen(true);
            }

        }

        else {
            setError(true);
        }

    };
    const handleClose = () => {
        setOpen(false);
    }

    const getOtpRequest = () => {
        const to = "92" + contact.substring(1);


        axios.get(baseApiUrl + `Customer/getOtp?to=${to}`).then((res) => {
            if (res) {
                setRequestId(res.data.requestId);
                console.log(res.data);
                setOtpClicked(true);
            }
        }).catch(err => {
            console.log(err);
        })

    }

    const verifyOtpRequest = () => {

        axios.get(baseApiUrl + `Customer/verifyOtp?requestId=${requestId}&code=${otp}`)
            .then(res => {
                if (res) {
                    console.log(res);
                    setOtpClicked(false);
                    handleClose();
                    addOrder();
                }

            }).catch(err => console.log(err))

    }

    const addOrder = () => {
        let cId = 0;
        let body1 = {
            name,
            contact,
            address
        };
        axios.post(baseApiUrl + 'Customer/add', body1).then((res) => {
            localStorage.setItem("cId", res.data.value);
            cId = Number(res.data.value);
        });



        let body = {
            sale: {
                customerId: 2,
                subTotal: Number(orderSummary.subTotal),
                salesTax: Number(orderSummary.salesTax),
                discount: 0,
                grandTotal: Number(orderSummary.grandTotal),
                saleDate: Date.now().toString(),
                shippingCharges: Number(orderSummary.shippingCharges),
                deliveryAddress: address,
                optDeliveryAddress: optionalAddress,
                billingAddress: checked ? address : payerAddress              
            },
            saleDetail: saleDetail,
            payment: {
                method: "COD",
                totalAmount: Number(orderSummary.grandTotal),
                amountReceived : 0,
                fbrCharges : 0,
                changeAmount : 0
            }
        };

        console.log(body);
        try{
            axios.post(baseApiUrl + 'Customer/addOrder', body).then((res) => {
                if (res.data.status == '200') {
                    // resetting redux store to initial
                    handleStoreReset();
                    setDisplay(true);
                    setTimeout(()=>{window.location.reload()}, 2500)
                }
    
            }).catch((err) => {
                console.log(err.request);
            })
        }
        catch(err){
            console.log("Error in making request. " + err.message +"\n"+ err.respose+"\n" + err.request);
        }
       
    }






    return (
        <div className='checkout-main'>
            <Alert severity="error" style={{ visibility: error ? 'visible' : 'hidden', margin: 0 }}>Enter Valid Data</Alert>
            <Alert severity="success" style={{ display: display ? 'block' : 'none', margin: 0 }}>Order Placed Successfully</Alert>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {!otpClicked && <div className='otp'>
                        <h3>Get OTP</h3>
                        <input type='number' minLength={11} maxLength={11} placeholder='Enter your Mobile No' defaultValue={contact} onChange={e => setContact(e.target.value)} />
                        <button type='submit' onClick={getOtpRequest}>Get OTP</button>
                    </div>
                    }
                    {
                        otpClicked && <div className='otp'>
                            <Alert severity='success'>Otp Sent. Enter Otp to verify</Alert>
                            <h3>Verify OTP</h3>
                            <input type='number' maxLength={6} placeholder='Enter Otp here' onChange={e => setOtp(e.target.value)} />
                            <button onClick={verifyOtpRequest}>Verify OTP</button>
                        </div>
                    }
                </Box>
            </Modal>

            <div className='checkout'>

                <div className='left'>
                    <div className='shipping-form'>
                        <h3 className='form-heading'>Shipping Address</h3>
                        <div className='form' onMouseEnter={() => setError(false)}>

                            <input type='text' placeholder='Enter your full name' onChange={(e) => { setName(e.target.value) }} />
                            <input type='text' placeholder='Enter your complete Address' onChange={(e) => { setAddress(e.target.value) }} />
                            <input placeholder='Enter your Contact' type='number' className='pno' minLength={11} maxLength={11} onChange={(e) => { setContact(e.target.value) }} />
                            <input type='text' placeholder='Enter Optional Address(Incase there is problem with 1st)' onChange={(e) => { setOptionalAddress(e.target.value) }} />
                            <label>Use as Billing Address <input type='checkbox' checked={checked} onChange={() => { setChecked(!checked) }} className='checkbox' /></label>

                        </div>
                    </div>
                    {!checked &&

                        <div className='billing-form'>
                            <h3 className='form-heading'>Billing Address</h3>
                            <div className='form' onMouseEnter={() => setError(false)}>
                                <input type='text' placeholder='Enter payer full name' onChange={(e) => { setPayerName(e.target.value) }} />
                                <input type='text' placeholder='Enter payer complete Address' onChange={(e) => { setPayerAddress(e.target.value) }} />
                                <input placeholder='Enter payer Contact' type='number' className='pno' minLength={11} maxLength={11} onChange={(e) => { setPayerContact(e.target.value) }} />
                            </div>
                        </div>
                    }
                </div>
                <div className='right'>
                    <h3>Order Summary</h3>
                    <div className='summary-data'>
                        <p className='row-heading'>Total Items</p>
                        <p className='row-data'>{`${orderSummary.totalItems}`}</p>
                    </div>
                    <hr />
                    <div className='summary-data'>
                        <p className='row-heading'>SubTotal</p>
                        <p className='row-data'>{`$${orderSummary.subTotal}`}</p>
                    </div>
                    <hr />
                    <div className='summary-data'>
                        <p className='row-heading'>SalesTax</p>
                        <p className='row-data'>{`$${orderSummary.salesTax}`}</p>
                    </div>
                    <hr />
                    <div className='summary-data'>
                        <p className='row-heading'>ShippingCharges</p>
                        <p className='row-data'>{`$${orderSummary.shippingCharges}`}</p>
                    </div>
                    <hr />
                    <div className='summary-data'>
                        <p className='row-heading'>GrandTotal</p>
                        <p className='row-data g-total'>{`$${orderSummary.grandTotal}`}</p>
                    </div>

                    <button onClick={handleOpen} className='order-btn'>Place Order</button>
                </div>



            </div>

        </div>
    )
}



{/* 
 const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [requestId, setRequestId] = useState('');
  const [verified, setVerified] = useState(false);
  const [message, setMessage] = useState('');

  const handleGenerateOtp = async () => {
    try {
      const response = await nexmo.verify.request({
        number: phoneNumber,
        brand: '<YOUR_BRAND_NAME>',
        code_length: 6
      });
      setRequestId(response.request_id);
      setMessage('OTP sent to your phone number!');
    } catch (error) {
      console.error(error);
      setMessage('Failed to generate OTP. Please try again later.');
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await nexmo.verify.check({
        request_id: requestId,
        code: otp
      });
      if (response.status === '0') {
        setVerified(true);
        setMessage('OTP verified successfully!');
      } else {
        setMessage('Invalid OTP. Please try again.');
      }
    } catch (error) {
      console.error(error);
      setMessage('Failed to verify OTP. Please try again later.');
    }
  };




*/}